// ** Logo
import logo from '@src/assets/images/logo/Rentx logo-01.png'
const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader'>
     <img src={logo} alt="logo" style={{height: 'auto', width: '20%'}}  />
    </div>
  )
}

export default SpinnerComponent
